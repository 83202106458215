import React, { useRef, useEffect, useState } from 'react';
import PubSub from 'pubsub-js'
// import Draggable from 'react-draggable';
import Draggable, {DraggableCore} from 'react-draggable';
import {throttle, debounce} from 'throttle-debounce'

const SliderDock = ({current, length}) => {
  const dockRef = useRef()
  const cartoucheRef = useRef()
  
  const [x, setX] = useState(0)
  const [controlledPosition, setControlledPosition] = useState({
    x: 0, 
    y: -16
  })
  const [isDragging, setIsDragging] = useState()

  const isFirst = current === 0
  const isLast = current === length - 1

  useEffect(() => {
    if(isDragging)return
    // console.log(current, isDragging)
    setControlledPosition({
      x: _getCurrentX(),
      y: -16
    })
  }, [current])

  useEffect(() => {
    _update()
  }, [x])

  const _getCurrentX = () => {
    // console.log(isDragging)
    const containerWidth = _getContainerWidth()
    // const current = _getCurrent()
    const currentX = (containerWidth * current) / (length -1)
    // console.log(containerWidth, current, length, currentX)
    return currentX
  }

  const _getContainerWidth = () => {
    // console.log(cartoucheRef)
    if(!cartoucheRef.current)return 0
    const cartoucheWidth = Math.round(cartoucheRef.current.getBoundingClientRect().width)
    const boundWidth = Math.round(dockRef.current.getBoundingClientRect().width)
    return boundWidth - cartoucheWidth
  }

  const _getCurrent = () => {
    const currentSlide = Math.round((current * 100) / (length - 1))
    // console.log("currentSlide", currentSlide)
    return currentSlide
  }
  // const _getPos = () => {
  //   // return (current * 100) / (length - 1)
  //   if(isFirst)
  //     return 0
  //   else if(isLast)
  //     return 100
  //   else
  //     return (current * 100) / (length - 1)
  // }

  const _getCartoucheClass = () => {
    if(isFirst)
      return 'is-first'
    else if(isLast)
      return 'is-last'
    else return ''
  }

  const _onClick = (e) => {
    const bounding = dockRef.current.getBoundingClientRect()
    // console.log(bounding.x)
    const offsetX = e.pageX - 10
    const percX = (offsetX * 100) / bounding.width
    const currentSlide = Math.round((percX * length) / 100) - 1
    PubSub.publish("SLIDE", currentSlide)
  }

  const _update = () => {
    const containerWidth = _getContainerWidth()
    // console.log(cartoucheWidth, boundWidth,width)
    const percX = (x * 100) / containerWidth
    const currentSlide = Math.round((percX * length) / 100) - 1
    // console.log(currentSlide)
    
    PubSub.publish("SLIDE", currentSlide)
  }

  // const _getCurrentSlideByX = (pos) => {
  // }
  

  const _handleDrag = (e, data) => {
    // console.log(data.x, controlledPosition.x)
    // console.log("_handleDrag")
    setIsDragging(true)
    _dragUpdate(data)
  };
  const _handleDragStop = (e, data) => {
    // console.log("_handleDragStop")
    _dragUpdate(data)
    setTimeout(() => {
      setIsDragging(false)
    }, 1000)
  }
  const _dragUpdate = (data) => {
    // console.log(isDragging)
    setX(data.x)
    setControlledPosition({x: data.x, y: -16})
  }
  
// console.log(controlledPosition)
  return (
    <div className="slider-dock psa w100 curp" ref={dockRef} onClick={_onClick}>
      <Draggable
        axis="x"
        position={controlledPosition}
        bounds={"parent"}
        onDrag={throttle(150, _handleDrag)}
        onStop={_handleDragStop}>
        <div 
          ref={cartoucheRef}
          // onClick={_onClick}
          className={`cartouche psa ${_getCartoucheClass()}`} 
          style={{
            // left: `${_getPos()}%`
        }}>
          {(current + 1)}/{length}
        </div>
      </Draggable>

      
    </div>
  );
};

export default SliderDock;