import React, { useContext, useEffect, useState, useRef } from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import { OauthContext } from './index'
import { _getLocalizedText } from '../../core/utils'
import BackgroundImage from 'gatsby-background-image'
// const logo = require("../../images/tara-logo.svg")

const query = graphql `
  query {
    global: prismicGlobal {
      ..._prismicGlobal
    }
  }
`

const Login = ({password, image}) => {
  const _OauthContext = useContext(OauthContext)
  const { isOauth, dispatch } = _OauthContext

  const { global } = useStaticQuery(query)

  const [state, setState] = useState()

  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    console.log()
  }, [global.data.template])
  // console.log("Login isOauth", isOauth, dispatch, _OauthContext)
// console.log(password)
  const _onSubmit = (e) => {
    // console.log(e)
    e.preventDefault();
    if(password === state){
      dispatch({ type: "LOGIN", payload: true })
    }else{
      alert("Code invalide")
    }
  }

  return (
    <div className="oauth login modal">
      <div id="bg">
        <BackgroundImage fluid={image.fluid} className="bg-img h100" />
      </div>
      <div className="inner">
        <div className="container-fluid">
          <form action="" onSubmit={_onSubmit}>
            <div className="form-row">
              <input 
                ref={inputRef}
                type="password" 
                name="passwd" 
                placeholder={"Password"}
                onChange={(e) => setState(e.target.value)}
                />
              <button type="submit">
                <span className="icon icon-arrow-e"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default Login;