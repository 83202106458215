import React, {useMemo, useEffect} from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import SEO from "../components/seo"
// import {RichText} from 'prismic-reactjs';
import { Oauth } from "../components/oauth/index"
import SliderSlices from "../components/slices/SliderSlices"
import Contact from "../components/Contact"

export const pageQuery = graphql`
  query Presentation($uid: String!, $lang: String!){
    prismicPresentation(uid: {eq: $uid}, lang: {eq: $lang}) {
      data{
        password
        title{text}
        # texte{text}
        image {
          ...sharp
        }
        body {
          ... on PrismicPresentationBodyTextes {
            slice_type
            primary {
              colonnes
              title {
                text
              }
            }
            items {
              texte {
                raw
              }
            }
          }
          ... on PrismicPresentationBodyTexteImages {
            slice_type
            primary {
              title {
                text
              }
              texte {
                text
                raw
              }
            }
            items {
              image {
                url
                ...sharp
              }
            }
          }
          ... on PrismicPresentationBodyTexteMosaique {
            slice_type
            primary {
              colonnes
              title {
                text
              }
              texte {
                text
                raw
              }
            }
            items {
              texte {
                raw
              }
            }
          }
        }
      }
    }
  }
`

const IS_BROWSER = typeof window !== 'undefined'

const Presentation = ({ path, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])
  
  const { password, title, seo_description, seo_image, image, body } = data.prismicPresentation.data

// console.log(password)
  return (
    <div className="page-template presentation">
      <SEO
        pageTitle={title.text || ''}
        pageDescription={''}
        // pageBanner={seo_image ? seo_image.url : ''}
        template="template-presentation"
        pathname={path}
        page
        noIndex={true}
      />
      
      {password
      ? <Oauth image={image} password={password}>
          <SliderSlices input={body} />
        </Oauth>
      : <SliderSlices input={body} />
      }
      
      <Contact />
      
    </div>
  )
}

export default Presentation
