import React, { useEffect } from "react"
import PubSub from 'pubsub-js'
import {RichText} from 'prismic-reactjs';

const Texte = ({ input }) => {
  // console.log(input)
  const {
    // title,
    colonnes
  } = input.primary
  
  const {
    items
  } = input

  const column = 12 / colonnes

  useEffect(() => {
    _customizeCartouche()

    const btnContact = document.querySelectorAll("span.btn-contact")
    btnContact.forEach(el => {
      // console.log(el.dataset)
      // el.removeEventListener("click", (e) => _onClickContact(e))
      if(!el.dataset.hasEvt){
        el.addEventListener("click", (e) => _onClickContact(e))
        el.dataset.hasEvt = true
      }
    })

    return () => {
      if(btnContact.length){
        btnContact.forEach(el => {
          el.removeEventListener("click", (e) => _onClickContact(e))
          el.dataset.hasEvt = false
        })
      }
    }
  }, [])

  const _customizeCartouche = () => {
    const cartouches = document.querySelectorAll(".cartouche")
    cartouches.forEach(el => {
      el.parentNode.classList.add("has-cartouche")
    })
  }

  const _onClickContact = (e) => {
    // console.log(e.target)
    PubSub.publish("CONTACT", true)
  }

	return (
		<section className="slice slice-textes">
			{/* <div className={`column column-${colonnes}`}>
        {items.map((item, i) => (
          <div className={`column-item `} key={i}>
            <div className="texte fL">{RichText.render(item.texte.raw)}</div>
          </div>
        ))}
      </div> */}
      {/* {colonnes} */}
      <div className="row">
        {items.map((item, i) => (
          <div className={`column-item col-md-${column} col-xs-12`} key={i}>
            <div className="texte fL">{RichText.render(item.texte.raw)}</div>
          </div>
        ))}
      </div>
		</section>
    
	)
}

export default Texte
