import React from 'react';
import {RichText} from 'prismic-reactjs';
import BackgroundImage from 'gatsby-background-image'

const TexteImages = ({input}) => {
  // console.log(input)
  const {
    // title,
    texte
  } = input.primary
  
  const {
    items
  } = input

  const column = 12 / items.length
  // const isImageTexte = items[0].texte.raw[0].type === 'image'
  // console.log(items[0].texte.raw[0])
// console.log(texte.text)
	return (
		<section className="slice slice-texte-images">
      
      {texte && texte.text &&
        <div className="texte fL strong">{RichText.render(texte.raw)}</div>
      }
      
			<div className={`row`}>
        {items.map((item, i) => (
          <div className={`col-md-4 col-xs-12 `} key={i}>
            <div className={`image-container ${i === items.length - 1 ? 'is-last' : ''}`}>
              <div className="outter">
                <div className="inner">
                  <div className="h100">
                    <BackgroundImage 
                      fluid={item.image.fluid}
                      className="h100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
		</section>
	)
};

export default TexteImages;