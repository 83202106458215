import React, { createContext, useReducer } from 'react'
import Login from './Login'
// import LocaleSwitcher from '../ui/LocaleSwitcher'
// import CookieConsent from '../ui/CookieConsent'
import './index.scss'

const OauthContext = createContext()

// const initialState = process.env.NODE_ENV == 'production' || false
const initialState = false

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN': return true
    case 'LOGOUT': return false
    default: throw new Error('Unexpected action')
  }
}

const Oauth = (props) => {
  const [isOauth, dispatch] = useReducer(reducer, initialState)
  // console.log("isOauth", isOauth)
  // console.log(props)
  return (
    <OauthContext.Provider value={{ isOauth, dispatch }}>
      <div className={isOauth ? '' : 'oauth-wrapper'}>
        {isOauth
          ? props.children
          : <Login {...props} />
          }
      </div>
    </OauthContext.Provider>

  )
}

export { OauthContext, Oauth }
