import React, { useEffect, useRef } from 'react';
import {RichText} from 'prismic-reactjs';

const TexteMosaique = ({input}) => {
  // console.log(input)
  const mosaicItemContainer = useRef()
  const {
    // title,
    texte,
    colonnes
  } = input.primary
  
  const {
    items
  } = input

  const column = 12 / colonnes
  const isImageTexte = items[0].texte.raw[0].type === 'image'
  const hasIntroTexte = texte.text !== ''
  // console.log(items)

  useEffect(() => {
    // _onResize()
    // document.addEventListener("resize", _onResize)

    // return () => document.removeEventListener("resize", _onResize)
  }, [])

  // const _onResize = () => {
  //   const textes = mosaicItemContainer.current.querySelectorAll(".is-texte .texte")
  //   console.log(textes)
  //   let maxHeight = 0
  //   let parentHeight
  //   textes.forEach(el => {
  //     const { height } = el.getBoundingClientRect()
  //     // console.log(height)
  //     parentHeight = el.parentNode.getBoundingClientRect().height
  //     if(height > maxHeight)maxHeight = height
  //   })
  //   const paddingTop = (parentHeight - maxHeight) * 0.5
  //   textes.forEach(el => {
  //     el.style.paddingTop = paddingTop+"px"
  //   })
  //   console.log(parentHeight, maxHeight)

  // }

	return (
		<section className="slice slice-texte-mosaique">
      {hasIntroTexte &&
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="texte fL">{RichText.render(texte.raw)}</div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="row" ref={mosaicItemContainer}>
              <Mosaique items={items} column={column} isImageTexte={isImageTexte} />
            </div>
          </div>
        </div>
      }

      {!hasIntroTexte &&
        <div className="row" ref={mosaicItemContainer}>
          <Mosaique items={items} column={column} isImageTexte={isImageTexte} />
        </div>
      }
		</section>
	)
};

const Mosaique = ({items, column, isImageTexte}) => (
  items.map((item, i) => (
    <div className={`col-md-${column} col-xs-12 `} key={i}>
      <div className={`item ${i === items.length - 1 ? 'is-last' : ''}`}>
        <div className="outter">
          <div className="inner">
            <div className={`h100 ${isImageTexte ? 'is-image' : 'is-texte'}`}>
              <div className="texte fL">{RichText.render(item.texte.raw)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
)

export default TexteMosaique;