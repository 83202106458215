import React, {useState, useEffect, useCallback, useRef} from 'react';
import PubSub from 'pubsub-js'
import Slider from "react-slick";
import {throttle, debounce} from 'throttle-debounce'
// import CardHome from './CardHome';
// import CardMarques from './CardMarques';
// import CardImage from './CardImage';
// import CardVide from './CardVide';
// import CardTexte from './CardTexte';
import Textes from './Textes';
import TexteMosaique from './TexteMosaique';
import TexteImages from './TexteImages';
import SliderDock from './SliderDock'

const SlicesSlider = ({input}) => {
  const [current, setCurrent] = useState(0);
  const [length, setLength] = useState(input.length || 0);
  const [isMobile, setIsMobile] = useState(undefined)
  // const [isEnd, setIsEnd] = useState(false)

  const slider = useRef()
  // console.log(input.length)

  useEffect(() => {
    if(window.innerWidth < 1024 && 'ontouchstart' in window)setIsMobile(true)
    /**
     * delay calc header height for mobile until desc is updated
     */
    
    // document.addEventListener("resize", _onResize)

    const token = PubSub.subscribe("SLIDE", (e,d) => {
      slider.current.slickGoTo(d)
    })

    return () => {
      // document.removeEventListener("resize", _onResize)
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    const delay = isMobile 
      ? 500 
      : 0
      
    setTimeout(() => {
      // _onResize()
    }, delay)
  }, [isMobile])

  // const _onResize = () => {
  //   const headerHeight = Math.round(document.querySelector("header").getBoundingClientRect().height)
  //   // console.log("headerHeight", headerHeight)
  //   document.documentElement.style.setProperty('--headerHeight', headerHeight + "px");
  // }

  useEffect(() => {
    const token = PubSub.publish("SLIDER_CHANGE", {
      slideDesc: input[current].primary.title.text
    })

    return () => PubSub.unsubscribe(token)
  }, [current, length])

  const _onEdge = () => {
    // _triggerEnd()
  }
  
  const _renderSlices = (slices) => {
    // console.log(slices)
    const _slice = slices.map((slice, i) => {
      if(slice === null)return null
      // console.log(slice.slice_type)
      switch (slice.slice_type) {
        case "textes":
          return <Textes key={i} input={slice} />;
        case "texte_mosaique":
          return <TexteMosaique key={i} input={slice} />;
        case "texte_images":
          return <TexteImages key={i} input={slice} />;
        
        default:
          return null;
      }
    });

    return _slice;
  }

  const _sliderAfterChange = (index) => {
    // console.log(index)
    setCurrent(index)
  }

  const _sliderBeforeChange = (oldIndex, newIndex) => {
    
  }

  // const _prev = () => {
  //   slider.current.slickPrev();
  // }

  // const _next = () => {
  //   slider.current.slickNext();
  // }

  const settings = {
    dots: false,
    infinite: false,
    // speed: isMobile ? 400 : 0,
    speed: 400,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // variableWidth: true,
    swipe: true,
    centerPadding: isMobile ? 100 : 0,
    // fade: !isMobile,
    // lazyLoad: true,
    // onInit: () => setCurrent(0),
    // beforeChange: (current, next) => setCurrent(current),
    // afterChange: current => setCurrent((current+1))
    beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
    // onSwipe: _onSwipe
    onEdge: _onEdge
  };

  return (
    <div className={"slider-slices"} >

      <Slider ref={slider} {...settings}>
        {input && _renderSlices(input)}
      </Slider>

      <SliderDock current={current} length={length} />
      
    </div>
  );
};

export default SlicesSlider;